import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { RunnerService } from '../runner.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  title = '';
  profile = null;
  isBack = false;

  constructor(
    public router: Router,
    private appService: AppService,
    private runnerService: RunnerService,
    private location: Location) {
      this.router.events.subscribe((event) => {
        this.profile = this.runnerService.getRunnerDetails();
      });
    }


  ngOnInit() {
    this.getTitle();
    this.profile = this.runnerService.getRunnerDetails();
  }

  getTitle() {
    this.appService.getTitle().subscribe(appTitle => this.title = appTitle);
  }

  goToCalendar() {
    this.profile.countOfUnreadMessages = 0;
    this.router.navigate(['calendar']);
  }

  goBack() {
    this.router.navigate(['schedule']);
  }

}
