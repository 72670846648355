import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbAccordionModule, NgbDatepickerModule,
  NgbTabsetModule, NgbModalModule, NgbAlertModule, NgbDropdownModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SigninComponent } from './signin/signin.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LogComponent } from './log/log.component';
import { ChatComponent } from './chat/chat.component';
import { DetailsComponent } from './log/details.component';
import { AccountComponent } from './account/account.component';
import { CoachComponent } from './coach/coach.component';
import { CoachLogComponent } from './coach-log/coach-log.component';
import { ProfileComponent } from './profile/profile.component';
import { CalenderComponent } from './calender/calender.component';
import { PopupComponent } from './popup/popup.component';
import { TimerPipe } from './pipe/timer.pipe';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    ScheduleComponent,
    HeaderComponent,
    FooterComponent,
    LogComponent,
    ChatComponent,
    DetailsComponent,
    AccountComponent,
    CoachComponent,
    CoachLogComponent,
    ProfileComponent,
    CalenderComponent,
    PopupComponent,
    TimerPipe
  ],
  imports: [
    BrowserModule,
    NgbAccordionModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbTabsetModule,
    NgbAlertModule,
    NgbDropdownModule,
    NgbTimepickerModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
