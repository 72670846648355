import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { RunnerService } from '../runner.service';
import { WorkoutService } from '../workout.service';
import { NgbModal, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbTimeStringAdapter } from '../ngb-time-string-adapter';
import { StravaService } from '../strava.service';
import { ReferralService } from '../referral.service';
import { environment } from '../../environments/environment';
import { jsonpCallbackContext } from '@angular/common/http/src/module';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [{provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter}]
})
export class ProfileComponent implements OnInit {

  @ViewChild('fileInput') fileInput;
  @ViewChild('popup') popup;

  profile: any;
  background: any;
  imageSrc: any;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordMisMatch: boolean;
  weekRange: any = {};
  fromDate: any;
  toDate: any;
  maxDate: any;
  minDate: any;
  phonenumber: string;
  isCoachView = false;
  attendance: number;
  paceTime: string;
  seconds = true;
  timechart: any;
  paceInputError: boolean;
  newReferral: any = {
  };
  unityWeekReferrals: any = [];
  unitWeekNameReferralError = false;
  unitWeekEmailReferralError = false;
  unityWeekAddError = false;
  unityWeekDates: string;

  constructor(
    private appService: AppService,
    private runnerService: RunnerService,
    private workoutService: WorkoutService,
    private stravaService: StravaService,
    private modalService: NgbModal,
    private referralService: ReferralService,
    private route: ActivatedRoute) {
      route.params.subscribe(val => {
        this.phonenumber = this.route.snapshot.paramMap.get('phonenumber');
      });
    }

  ngOnInit() {
    this.appService.setTitle('Profile');
    const today = new Date();
    this.maxDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
    this.unityWeekDates = `${environment.unityWeekStart} - ${environment.unityWeekEnd}`;
    this.getProfile();
  }

  testNamePattern(elementValue){
    var namePattern = /^[A-Za-z ]+$/;
    return namePattern.test(elementValue);
  }

  testEmailPattern(elementValue){
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
  }

  addReferral(){
    if (!this.newReferral.referral_name ||
          !this.testNamePattern(this.newReferral.referral_name)){
            this.unitWeekNameReferralError = true;
    } else {
      this.unitWeekNameReferralError = false;
    }
    if (!this.newReferral.referral_email ||
            !this.testEmailPattern(this.newReferral.referral_email)){
              this.unitWeekEmailReferralError = true;
    } else {
      this.unitWeekEmailReferralError = false;
    }
    if (this.unitWeekNameReferralError || this.unitWeekEmailReferralError) {
      return;
    }
    this.referralService.addReferral(this.newReferral.referral_name, this.newReferral.referral_email).subscribe(
      data => {
        // console.log("data: "+data);
        this.unityWeekAddError=false;
        this.unityWeekReferrals = data;
        this.newReferral = {
          "referral_name": "",
          "referral_email": ""
        };
        this.runnerService.setUnityWeekReferrals(data);
      },
      err => {
        this.unityWeekAddError = true;
      });

  }

  deleteReferral(idUnityWeekReferralToDel){
    this.referralService.removeReferral(idUnityWeekReferralToDel.referral_name,idUnityWeekReferralToDel.referral_email).subscribe(data => {
      this.unityWeekReferrals = data;
      this.runnerService.setUnityWeekReferrals(data);
    });
  }

  getProfile() {
    if(this.phonenumber) {
      const currentProfile = this.runnerService.getRunnerDetails().profile;
      this.unityWeekReferrals = currentProfile.referrals;
      this.isCoachView = this.phonenumber !== currentProfile.phonenumber;
      if(this.isCoachView) {
        this.appService.setTitle('Coach View');
      }
      // get runner profile
      this.workoutService.getRunnerProfile(this.phonenumber).subscribe(data => {
        this.sucessProfile(data);
      });
    } else {
      this.isCoachView = false;
      this.workoutService.getProfile().subscribe(data => {
        this.sucessProfile(data);
      });
    }
  }

  sucessProfile(data) {
    this.profile = data.profile;
    this.unityWeekReferrals = this.runnerService.getRunnerDetails().profile.referrals;
    this.background = data.background;
    this.attendance = data.attendance;
    this.timechart = data.timechart;
    this.getProfilePic(data.profile.phonenumber);
  }

  getProfilePic(phonenumber) {
    this.imageSrc = this.workoutService.getProfilePics() + phonenumber;
  }

  upload(files: FileList) {
    if (files) {
      const fileData = files.item(0);

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(fileData);

      const formData = new FormData();
      formData.append('myFile', fileData);

      this.workoutService.setProfilePic(formData).subscribe(res => {
        // do stuff w/my uploaded file
      });
    }
  }

  resetPassword() {
    this.modalService.open(this.popup, {centered: true});
  }

  reset() {
    this.passwordMisMatch = false;
    if(this.newPassword === this.confirmPassword) {
      this.workoutService.updatePassword(this.newPassword).subscribe(data => {
        this.modalService.dismissAll();
      });
    } else {
      this.passwordMisMatch = true;
    }
  }

  downlaodLog() {
    if(this.fromDate && this.toDate) {
      this.weekRange.fromDate = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.weekRange.toDate = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
    } else {
      return;
    }
    this.workoutService.exportLog(
        this.profile.phonenumber,
        this.weekRange.fromDate,
        this.weekRange.toDate).subscribe(blob => {
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        });
  }

  nextDay(){
    const pipe = new DatePipe('en-US');
    const date = new Date();
    const lastday = date.getDate() - (date.getDay() - 1);
    date.setDate(lastday);
    const range = {toDate: '', fromDate: ''};
    range.fromDate = pipe.transform(date, 'yyyy-MM-dd');
    date.setDate(date.getDate() + 6);
    range.toDate = pipe.transform(date, 'yyyy-MM-dd');
    return range;
  }

  onDateChange() {
    this.toDate = null;
  }

  calculateTimetrial() {
    if(!this.paceTime) {
      this.paceInputError = true;
      return;
    }
    this.paceInputError = false;
    this.workoutService.setTimeTrial(this.paceTime).subscribe(data => {
      this.paceTime = '';

      this.profile.TimeTrialValue = data.TimeTrialValue;
      this.profile.TargetETC = data.TargetETC;
      this.profile.GoalPace = data.GoalPace;
      this.profile.TempoPace = data.TempoPace;

      this.timechart = data.timechart;
    });
  }

  authorizeStrava() {
    // return 'https://www.strava.com/oauth/authorize?client_id=36869&response_type=code&approval_prompt=force&scope=read,activity:read_all&redirect_uri=https://rhapp.in/api/strava/callback?phonenumber=' + this.profile.phonenumber;
    return 'https://www.strava.com/oauth/authorize?client_id=36869&response_type=code&approval_prompt=force&scope=read,activity:read_all&redirect_uri=http://rhapp.in/api/strava/callback?phonenumber=' + this.profile.phonenumber;
    // this.stravaService.authorize('9845635527').subscribe(data => {

    // });
  }

}
