import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { RunnerService } from '../runner.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  isCoach: boolean;

  constructor(private appService: AppService, private runnerService: RunnerService) { }

  ngOnInit() {
    this.appService.setTitle('Account');
    this.isCoach = this.runnerService.getIsCoach();
  }

  logout() {
    this.runnerService.logout();
  }

}
