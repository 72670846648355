export class Workout {
  'idWorkoutLog': number;
  'phonenumber': string;
  'scheduledworkoutdate': string;
  'IsGroupWorkout': number;
  'isPresentByCoach': string;
  'ActualWorkoutDate': string;
  'ActualWarmup': string;
  'ActualWorkout': string;
  'ActualCoolDown': string;
  'Overalltime': string;
  'AveragePace': string;
  'Splits': any[];
  'EffortLevel': string;
  'Hydration': string;
  'InjuryIssues': string;
  'SleepQuality': string;
  'PrerunNutrition': string;
  'DuringrunNutrition': string;
  'Comments': string;
  'AcknowledgedByCoach': string;
  'unityweeklog': any[];

  constructor(phonenumber: string, scheduledworkoutdate: string) {
    this.phonenumber = phonenumber;
    this.scheduledworkoutdate = scheduledworkoutdate;
  }
}

