import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RunnerService } from './runner.service';

@Injectable({
  providedIn: 'root'
})
export class CoachGuardService implements CanActivate {
    constructor(
        private router: Router,
        private runnerService: RunnerService
      ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const profile = this.runnerService.getRunnerDetails().profile;
      if(this.runnerService.getIsCoach()) {
        return true;
      } else if(route.params.phonenumber === profile.phonenumber) {
        return true;
      } else {
        this.router.navigate( ['schedule']);
        return false;
      }
    }
}

