import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StravaService {

  private stravaUrl = environment.stravaUrl;
  private apiUrl = environment.baseUrl + 'strava';

  constructor(private http: HttpClient) { }

  private httpOptions = { headers: new HttpHeaders(), withCredentials: true };

  authorize(phonenumber: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    // const url = this.stravaUrl + 'authorize?client_id=36869&response_type=code&redirect_uri=https://rhapp.in/api/strava/callback?phonenumber=' + phonenumber + '&approval_prompt=force&scope=read,activity:read_all';
    const url = this.stravaUrl + 'authorize?client_id=36869&response_type=code&redirect_uri=https://rhapp.in/api/strava/callback?phonenumber=' + phonenumber + '&approval_prompt=force&scope=read,activity:read_all';
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  
  activities(fromDate?: string, toDate?: string) {
    if(!fromDate) {
      const d = new Date();
      const pipe = new DatePipe('en-US');
      fromDate = pipe.transform(d, 'yyyy-MM-dd');
    }
    if(!toDate) {
      toDate = fromDate;
    }
    const url = this.apiUrl + '/activities?fromdate=' + fromDate + '&todate=' + toDate;
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));

  }

  laps(activityId: string) {
    const url = this.apiUrl + '/activityLaps?activityId=' + activityId;
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private handleSuccessResponse = (response) => {

    return response.body;
  }
}
