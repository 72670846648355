export class Schedule {
  'CoolDownSchedule': string;
  'IsGroupWorkout': number;
  'StartTime': string;
  'WarmUpSchedule': string;
  'WorkoutSchedule': string;
  'daynumber': number;
  'idLocationMaster': any;
  'idScheduleTemplateMaster': number;
  'idSeasonMaster': number;
  'idWorkoutScheduleMaster': number;
  'scheduledworkoutdate': string;
  'weeknumber': number;
}

export class ScheduleUpdate{
  'phonenumber':	string;
  'scheduledworkoutdate':	string;
  'ActualWorkoutDate':	string;
  'ActualWarmup':	string;
  'ActualWorkout':	string;
  'ActualCoolDown':	string;
  'Overalltime':	string;
  'AveragePace':	string;
  'Splits':	string;
  'EffortLevel':	string;
  'Hydration':	string;
  'InjuryIssues':	string;
  'SleepQuality':	string;
  'Comments':	string;
  'PrerunNutrition':	string;
  'DuringrunNutrition':	string;

  constructor(schedule: Schedule) {
    // this.phonenumber = schedule.phonenumber ? schedule.phonenumber : '';
    // this.scheduledworkoutdate = schedule.scheduledworkoutdate ? schedule.scheduledworkoutdate : '';
    // this.ActualWorkoutDate = schedule.ActualWorkoutDate ? schedule.ActualWorkoutDate : '';
    // this.ActualWarmup = schedule.ActualWarmup ? schedule.ActualWarmup : '';
    // this.ActualWorkout = schedule.ActualWorkout ? schedule.ActualWorkout : '';
    // this.ActualCoolDown = schedule.ActualCoolDown ? schedule.ActualCoolDown : '';
    // this.Overalltime = schedule.Overalltime ? schedule.Overalltime : '';
    // this.AveragePace = schedule.AveragePace ? schedule.AveragePace : '';
    // this.Splits = schedule.Splits ? schedule.Splits : '';
    // this.EffortLevel = schedule.EffortLevel ? schedule.EffortLevel : '';
    // this.Hydration = schedule.Hydration ? schedule.Hydration : '';
    // this.InjuryIssues = schedule.InjuryIssues ? schedule.InjuryIssues : '';
    // this.SleepQuality = schedule.SleepQuality ? schedule.SleepQuality : '';
    // this.Comments = schedule.Comments ? schedule.Comments : '';
    // this.PrerunNutrition = schedule.PrerunNutrition ? schedule.PrerunNutrition : '';
    // this.DuringrunNutrition = schedule.DuringrunNutrition ? schedule.DuringrunNutrition : '';
  }
}
