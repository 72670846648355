import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { Schedule, ScheduleUpdate } from './schedule';
import { Workout } from './workout';

@Injectable({
  providedIn: 'root'
})
export class WorkoutService {

  private runnerUrl = environment.baseUrl + 'workout';

  constructor(private http: HttpClient) { }

  private currentSchedules = null;
  private stlinks = null;
  private activationExercies = null;
  private runningDrills = null;
  private Stretches = null;
  private footDrills = null;

  private httpOptions = { headers: new HttpHeaders(), withCredentials: true };

  getCurrentSchedule(): Schedule[] {
    if(this.currentSchedules) {
      return this.currentSchedules;
    } else {
      const runnerDetails = JSON.parse(localStorage.getItem('runner-details'));
      if (runnerDetails && runnerDetails.currentSchedule) {
        return runnerDetails.currentSchedule;
      }
      return [];
    }
  }

  setCurrentSchedule(schedules: Schedule[]) {
    this.currentSchedules = schedules;
  }

  getCurrentStlinks() {
    if(this.stlinks) {
      return this.stlinks;
    } else {
      const runnerDetails = JSON.parse(localStorage.getItem('runner-details'));
      if (runnerDetails && runnerDetails.stlink) {
        return runnerDetails.stlink;
      }
      return [];
    }
  }

  setStlinks(stlinks) {
    this.stlinks = stlinks;
  }

  getRunningDrillsLinks() {
    if(this.runningDrills) {
      return this.runningDrills;
    } else {
      const runnerDetails = JSON.parse(localStorage.getItem('runner-details'));
      if (runnerDetails && runnerDetails.runningDrills) {
        return runnerDetails.runningDrills;
      }
      return [];
    }
  }

  setRunningDrillsLinks(runningDrills) {
    this.runningDrills = runningDrills;
  }

  getStretchesLinks() {
    if(this.Stretches) {
      return this.Stretches;
    } else {
      const runnerDetails = JSON.parse(localStorage.getItem('runner-details'));
      if (runnerDetails && runnerDetails.Stretches) {
        return runnerDetails.Stretches;
      }
      return [];
    }
  }

  setStretchesLinks(Stretches) {
    this.Stretches = Stretches;
  }

  getActivationLinks() {
    if(this.activationExercies) {
      return this.activationExercies;
    } else {
      const runnerDetails = JSON.parse(localStorage.getItem('runner-details'));
      if (runnerDetails && runnerDetails.activationExercies) {
        return runnerDetails.activationExercies;
      }
      return [];
    }
  }

  setActivationLinks(activationExercies) {
    this.activationExercies = activationExercies;
  }

  getFootDrillsLinks() {
    if(this.footDrills) {
      return this.footDrills;
    } else {
      const runnerDetails = JSON.parse(localStorage.getItem('runner-details'));
      if (runnerDetails && runnerDetails.footDrills) {
        return runnerDetails.footDrills;
      }
      return [];
    }
  }

  setFootDrillsLinks(footDrills) {
    this.footDrills = footDrills;
  }

  getSchedule(phoneNumber: string, fromDate: string, toDate: string): Observable<any> {
    const url = this.runnerUrl + '/log' + '?phonenumber=' + phoneNumber + '&fromdate=' + fromDate + '&todate=' + toDate;
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  setSchedule(phoneNumber: string, workout: Workout) {
    const url = this.runnerUrl + '/log';
    // const scheduleUpdate = new ScheduleUpdate(workout);
    // scheduleUpdate.phonenumber = phoneNumber;

    return this.http.post(url, workout, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  markAttendance(scheduledworkoutdate: string, runners: any) {
    const url = this.runnerUrl + '/markAttendance';
    const data = {
      scheduledworkoutdate,
      runners
    }
    return this.http.put(url, data, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  updateAttendance(data: any) {
    const url = this.runnerUrl + '/updateAttendance';
    return this.http.put(url, data, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  getUnReadMessages(phoneNumber: string) {
    const url = this.runnerUrl + '/unReadMessages' + '?phonenumber=' + phoneNumber;
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  markMessageAsRead(messageId: number) {
    const url = this.runnerUrl + '/markMessageAsRead';
    const data = {
      idMessage: messageId
    };
    return this.http.put(url, data, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  getFeedback(phoneNumber: string, fromDate: string, toDate: string): Observable<any> {
    const url = this.runnerUrl + '/message' + '?phonenumber=' + phoneNumber + '&fromdate=' + fromDate + '&todate=' + toDate;
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  deleteFeedback(messageId: number) {
    const url = this.runnerUrl + '/message' + '?idMessage=' + messageId;
    return this.http.delete<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  setFeedback(phonenumber: string, scheduledworkoutdate: string, Message: string) {
    const url = this.runnerUrl + '/message';
    const feedbackObj = {
      phonenumber,
      scheduledworkoutdate,
      Message
    };
    return this.http.post<any>(url, feedbackObj, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  setProfilePic(imageData) {
    const url = environment.baseUrl + 'runner/profilepic';
    return this.http.post<any>(url, imageData).pipe(catchError(this.handleError('catch error')));
  }

  getProfilePic() {
    return environment.baseUrl + 'runner/profilepic';
  }

  getProfilePics() {
    return environment.baseUrl + 'runner/profilepics/';
  }

  getProfile() {
    const url = environment.baseUrl + 'runner/profile';
    return this.http.get(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  getRunnerProfile(phonenumber: string) {
    const url = environment.baseUrl + 'runner/profile?phonenumber=' + phonenumber;
    return this.http.get(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  updatePassword(password) {
    const url = environment.baseUrl + 'runner/password';
    const data = {
      password
    };
    return this.http.put(url, data, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  resetPassword(phonenumber) {
    const url = environment.baseUrl + 'runner/resetPassword';
    const data = {
      phonenumber
    };
    return this.http.put(url, data, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  exportLog(phoneNumber: string, fromDate: string, toDate: string) {
    const url = this.runnerUrl + '/exportLog' + '?phonenumber=' + phoneNumber + '&fromdate=' + fromDate + '&todate=' + toDate;
    return this.http.get(url, { responseType: 'blob' });
  }

  getRunner(scheduledworkoutdate: string, location: string, buddyGroupName: string): Observable<any> {
    let url = environment.baseUrl + 'runner?scheduledworkoutdate=' + scheduledworkoutdate;
    if(location) {
      url = url + '&location=' + location;
    }
    if(buddyGroupName) {
      url = url + '&bgname=' + encodeURIComponent(buddyGroupName);
    }
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  getUnAcknowledgedLogs(phoneNumber: string, fromDate: string, toDate: string) {
    const url = this.runnerUrl + '/unAcknowledgedLogs' + '?phonenumber=' + phoneNumber + '&fromdate=' + fromDate + '&todate=' + toDate;
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')));
  }

  setAcknowledge(phonenumber: string, workoutdate: string) {
    const url = this.runnerUrl + '/acknowledge';
    const acknowledgeObj = {
      phonenumber,
      workoutdates: [workoutdate]
    };
    return this.http.post<any>(url, acknowledgeObj, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  getUnityWeekStats(){
    const url = environment.baseUrl + 'runner/UnityWeekStats';
    return this.http.get<any>(url, this.httpOptions).pipe(catchError(this.handleError<any>('catch error')))
  }

  

  setTimeTrial(time: string) {
    const url = environment.baseUrl + 'runner/timetrial';
    return this.http.put<any>(url, { time }, this.httpOptions).pipe(catchError(this.handleError('catch error')));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private handleSuccessResponse = (response) => {

    return response.body;
  }
}
