import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RunnerService } from '../runner.service';
import { AppService } from '../app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutService } from '../workout.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  formModel = {
    mobileNumber: '',
    password: ''
  };
  signinError: boolean;
  phoneNumber: string;
  rememberme = true;
  @ViewChild('popup') popup;

  constructor(
    private router: Router,
    private appService: AppService,
    private runnerService: RunnerService,
    private workoutService: WorkoutService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.appService.setTitle('Welcome to Runner\'s High App');

    const user = JSON.parse(localStorage.getItem('login-user'));
    if(user) {
      this.formModel = user;
    }
  }

  signin(): void {
    this.signinError = false;
    this.runnerService.login(this.formModel.mobileNumber, this.formModel.password).subscribe(data => {
      if(data && data.status === 200) {
        if(this.rememberme) {
          const user = {
            mobileNumber: this.formModel.mobileNumber,
            password: this.formModel.password
          };
          localStorage.setItem('login-user', JSON.stringify(user));
        } else {
          localStorage.removeItem('login-user');
        }
        this.router.navigate(['schedule']);
      } else {
        this.signinError = true;
      }
    });
  }

  openPoppup() {
    this.modalService.open(this.popup, {centered: true});
  }

  resetPassword() {
    if(!this.phoneNumber) {
      return;
    }
    this.workoutService.resetPassword(this.phoneNumber).subscribe(data => {
      this.phoneNumber = '';
      this.modalService.dismissAll();
    });
  }

}
