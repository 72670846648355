import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Schedule } from '../schedule';
import { WorkoutService } from '../workout.service';
import { AppService } from '../app.service';
import { RunnerService } from '../runner.service';
import { Workout } from '../workout';
import { Feedback } from '../feedback';
import { DatePipe, formatDate } from '@angular/common';
import {NgbTimepickerConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { StravaService } from '../strava.service';


declare var jquery:any;
declare var $ :any;
class SplitAttribute {
  distance: string;
  timeObj: any;

  constructor(split?: any) {
    if(split && split.distance && split.time) {
      this.distance = split.distance;
      this.timeObj = {};
      const splits = split.time.split(':');
      this.timeObj.second = +splits[2];
      this.timeObj.minute = +splits[1];
      this.timeObj.hour = +splits[0];
    } else {
      this.distance = '';
      this.timeObj = {};
    }
  }
}

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {

  phonenumber: string;
  logDate: string;
  currentSchedule: Schedule = new Schedule();
  currentLog: Workout;
  currentFeedback: Feedback[] = [];
  profile: any;
  isCoach: boolean;
  name: string;
  successMessage: boolean;
  showMessages: string;
  newAttribute: SplitAttribute = new SplitAttribute();
  editAttribute: SplitAttribute = new SplitAttribute();
   time = {hour: 13, minute: 30};
    seconds = true;
    spinners = false;
  @ViewChild('popup') popup;
  activities: any[];
  isLoading: boolean;
  unityWeekReferralsDisplayList: any = [];
  selfDoneUnityWeekWorkoutDistance: number;


  toggleSeconds() {
    this.seconds = !this.seconds;
  }



  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private workoutService: WorkoutService,
    private appService: AppService,
    private runnerService: RunnerService,
    private stravaService: StravaService,
    private router: Router,
    config: NgbTimepickerConfig) {

    config.seconds = true;
    config.spinners = false;

    route.params.subscribe(val => {
      this.phonenumber = this.route.snapshot.paramMap.get('phonenumber');
      this.logDate = this.route.snapshot.paramMap.get('logDate');
      this.name = this.route.snapshot.queryParamMap.get('name');
      this.showMessages = this.route.snapshot.queryParamMap.get('messages');
      
      this.getScheduleLog(this.phonenumber, this.logDate);
    });
    this.profile = this.runnerService.getRunnerDetails().profile;
  }

  ngOnInit() {
    this.appService.setTitle('Workout Log');
    this.profile = this.runnerService.getRunnerDetails().profile;
    this.unityWeekReferralsDisplayList = this.getInitializedReferralListFromProfile();
    if(!this.phonenumber) {
      this.phonenumber = this.profile.phonenumber;
    }
    this.isCoach = this.phonenumber !== this.profile.phonenumber;

    if(this.isCoach) {
      this.getRunnerDetails(this.phonenumber);
    }

    this.getUnReadMessages();

    if(this.showMessages) {
      setTimeout(() => {
        this.scrollTo();
      }, 1000);
    }
  }

  getInitializedReferralListFromProfile(){
    const unityWeekReferralsLog = [];
    this.profile.referrals.forEach(function(row) {
      unityWeekReferralsLog.push({
        uwl_idUnityWeekReferral: row.idUnityWeekReferral,
        referral_name: row.referral_name,
        referral_email: row.referral_email,
        uwl_unit: "minutes"
      });
    });

    return unityWeekReferralsLog;
  }

  mergePreviouslyLoggedDistancesIntoDisplay(){
    console.log("value of unityWeekReferralsLog "+JSON.stringify(this.unityWeekReferralsDisplayList));
    console.log("value of currentLog.unitweeklog current workout"+JSON.stringify(this.currentLog.unityweeklog));
    const previouslyLoggedDistances = this.currentLog.unityweeklog;
    this.unityWeekReferralsDisplayList.forEach(function(row){
      const id = row.uwl_idUnityWeekReferral;
      console.log("matcher id "+id);
      const referalDetail = previouslyLoggedDistances.find((temp) => {
        return temp.uwl_idUnityWeekReferral === id;
      });
      console.log("referralDetail "+JSON.stringify(referalDetail));
      if (referalDetail){
        row.uwl_duration = referalDetail.uwl_duration;
      } else {
        row.uwl_duration = null;
      }
    });
    const selfLog = this.currentLog.unityweeklog.find( (temp) => {
      return temp.uwl_idUnityWeekReferral === null;
    });
    if (selfLog) {
      this.selfDoneUnityWeekWorkoutDistance = selfLog.uwl_duration;
    } else {
      this.selfDoneUnityWeekWorkoutDistance = null;
    }
  }

  // @HostListener('window:scroll', [])
  // onScroll(): void {
  // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //     if(this.isUnReadMessage) {
  //       this.getUnReadMessages();
  //     }
  //   }
  // }

  isUnityWeek() {
    return this.runnerService.isUnityWeek(this.logDate);
  }

  getUnReadMessages() {
    const logDate = this.logDate;
    this.workoutService.getUnReadMessages(this.phonenumber).subscribe(messages => {
      if(messages && messages.length) {
        const unUnReadMessages = messages.filter(message => {
          return formatDate(message.WorkoutDate, 'shortDate', 'en-US') === formatDate(logDate, 'shortDate', 'en-US');
        });
        if(unUnReadMessages && unUnReadMessages.length) {
          this.markAsRead(unUnReadMessages);
        }
      }
    });
  }

  markAsRead(unUnReadMessages) {
// tslint:disable-next-line: prefer-for-of
    for(let i = 0; i < unUnReadMessages.length; i++) {
      this.workoutService.markMessageAsRead(unUnReadMessages[i].idMessage).subscribe();
    }
  }

  scrollTo() {
    const elmnt = document.getElementById('target');
    elmnt.scrollIntoView();
  }

  getRunnerDetails(phoneNumber) {
    // this.workoutService.getRunner();
  }

  getScheduleLog(phoneNumber: string, logDate: string) {
    this.isLoading = true;
    this.workoutService.getSchedule(phoneNumber, logDate, logDate).subscribe(data => {
      if(data && data.Schedule && data.Schedule.length) {
        this.currentSchedule = data.Schedule[0];
        if(data.WorkoutLog && data.WorkoutLog[0]) {
          this.currentLog = data.WorkoutLog[0].workout;
          this.currentLog.Splits = data.WorkoutLog[0].splits;
          this.currentLog.unityweeklog = data.WorkoutLog[0].unityweeklog;
          if (this.isUnityWeek()) {
            this.mergePreviouslyLoggedDistancesIntoDisplay();
          }
          this.currentFeedback = data.WorkoutLog[0].messages;
        } else {
          this.currentLog = new Workout(phoneNumber, logDate);
          this.currentFeedback = [];
        }
      } else {
        this.router.navigate(['schedule']);
      }
      this.isLoading = false;
    });
  }

  saveLog() {
    this.currentLog.unityweeklog = this.unityWeekReferralsDisplayList.slice();
    this.currentLog.unityweeklog.push({
      "uwl_idUnityWeekReferral": null,
      "uwl_unit": "kms",
      "uwl_duration": this.selfDoneUnityWeekWorkoutDistance
    });
    this.workoutService.setSchedule(this.phonenumber, this.currentLog).subscribe(data => {
      this.successMessage = true;
      setTimeout(() => this.successMessage = false, 2000);
    });
  }

  nextLog() {
    this.isLoading = true;
    const nextDate = this.getDate(this.logDate, 1);
    if(this.isFutureDate(nextDate)) {
      //popup
      alert('Future dates cannot be logged');
      this.isLoading = false;
      return;
    }
    this.router.navigate(['log', this.phonenumber, nextDate], { queryParams: { name: this.name } });
    this.isLoading = false;
  }

  prevLog() {
    this.isLoading = true;
    const prevDate = this.getDate(this.logDate, -1);
    this.router.navigate(['log', this.phonenumber, prevDate], { queryParams: { name: this.name } });
    this.isLoading = false;
  }

  getDate(date: string, noOfDays: number) {
    const d = new Date(date);
    d.setDate( d.getDate() + noOfDays );
    const pipe = new DatePipe('en-US');
    return pipe.transform(d, 'yyyy-MM-dd');
  }

  readLog() {
    this.workoutService.setAcknowledge(this.phonenumber, this.logDate).subscribe(data => {
      this.router.navigate(['coachlog', this.phonenumber, this.logDate], { queryParams: { name: this.name } });
    });
  }

  isFutureDate(date) {
    let isFutureDate = false;
    const scheduledate = new Date(date);
    const today = new Date();
    if(scheduledate > today) {
      isFutureDate = true;
    }
    return isFutureDate;
  }

    addFieldValue() {
      if(!this.currentLog.Splits) {
        this.currentLog.Splits = [];
      }
      if(this.newAttribute.distance && this.newAttribute.timeObj) {
          const split = {
            distance: this.newAttribute.distance,
            time: this.newAttribute.timeObj.hour + ':' +
              this.newAttribute.timeObj.minute + ':' +
              this.newAttribute.timeObj.second
          };
          this.currentLog.Splits.push(split);
          this.newAttribute = new SplitAttribute();
      }
    }

    deleteFieldValue(index) {
      this.currentLog.Splits.splice(index, 1);
    }

    onDistancePress(event) {
      const inputKeyCode = event.keyCode ? event.keyCode : event.which;

      if (inputKeyCode !== null) {
          if (inputKeyCode === 45) {
            event.preventDefault();
          }
      }
    }

    getActivities() {
      this.stravaService.activities(this.logDate).subscribe(data => {
        if(data) {
          if(data.length > 1) {
            //popup to select activity
            this.activities = data;
            this.modalService.open(this.popup, {centered: true, size: 'lg'}).result.then(data => {
              console.log(data);
            });
          } else if(data.length === 1) {
            //get laps
            this.getLaps(data[0].idActivity);
          } else {
            // no activity
          }
        }
      });
    }

    editSplit(split) {
      this.currentLog.Splits.forEach(data => {
        data.isEdit = false;
      });
      this.editAttribute = new SplitAttribute(split);
      console.log(this.editAttribute);
      split.isEdit = true;
    }

    saveSplit(splitItem) {
      if(this.editAttribute.distance && this.editAttribute.timeObj) {
        splitItem.distance = this.editAttribute.distance;
        splitItem.time = this.editAttribute.timeObj.hour + ':' +
            this.editAttribute.timeObj.minute + ':' +
            this.editAttribute.timeObj.second;
        this.editAttribute = new SplitAttribute({});
      }
      splitItem.isEdit = false;
    }

    getLaps(activityId: string) {
      this.stravaService.laps(activityId).subscribe(data => {
        console.log(data);
        if(data && data.length) {
          data.forEach(element => {
            this.currentLog.Splits.push({distance: +element.lapDistance, time: element.lapElapsedTime});
          });
        }
      });
    }

    selectActivity(activityId: string) {
      this.getLaps(activityId);
      this.modalService.dismissAll(activityId);
    }

}
