import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { RunnerService } from '../runner.service';
import { Router } from '@angular/router';
import { WorkoutService } from '../workout.service';
import { DatePipe } from '@angular/common';
import { NgbDate, NgbDateStruct, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.css']
})
export class CoachComponent implements OnInit {
  profile;
  buddygroup: any;
  buddies: any[];
  selectedDate: any;
  currentDate: any;
  selectedTab: string;
  dateString: string;
  maxDate: any;
  imageSrc: string;
  locationList: any[];
  currentLocation: string;
  unreadLogs: any[];
  attandenceCount: number;

  constructor(
    private appService: AppService,
    private runnerService: RunnerService,
    private workoutService: WorkoutService,
    private router: Router) { }

  ngOnInit() {
    this.appService.setTitle('Runner list');

    this.profile = this.runnerService.getRunnerDetails().profile;
    this.currentDate = this.getToday();
    this.selectedTab = 'tab-buddy';
    this.dateString = 'Today';
    const today = new Date();
    this.maxDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};

    this.locationList = [
      'Jayanagar',
      'HSR',
      'CubbonQueens',
      'CubbonLibrary',
      'Whitefield',
      'RBR',
      'BGR',
      'Pune',
      'Gurgaon'
    ];
    this.currentLocation = this.profile.location;

    this.buddyGroupChange(this.profile.buddygroup[0]);
    this.getProfilePic();
  }

  getRunners() {
    if(this.selectedTab === 'tab-buddy') {
      this.workoutService.getRunner(this.currentDate, '', this.buddygroup.BuddyGroupName).subscribe(data => {
        this.buddies = this.sortRunners(data.runners);
        this.unreadLogs = data.unReadLogsCount;
        this.getUnreadLogCount();
        this.getAttendanceCount();
      });
    } else {
      this.workoutService.getRunner(this.currentDate, this.currentLocation, '').subscribe(data => {
        this.buddies = this.sortRunners(data.runners);
        this.unreadLogs = data.unReadLogsCount;
        this.getUnreadLogCount();
        this.getAttendanceCount();
      });
    }

  }

  getAttendanceCount() {
    let count = 0;
    this.buddies.forEach(buddy => {
      if(buddy.isPresentByCoach) {
        count++;
      }
    });
    this.attandenceCount = count;
  }

  sortRunners(array) {
    array.sort((a: any, b: any) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  getUnreadLogCount() {
    if(this.buddies && this.buddies.length && this.unreadLogs && this.unreadLogs.length) {
      for (let i = 0; i < this.buddies.length; i++) {
        const buddy = this.buddies[i];
        for (let j = 0; j < this.unreadLogs.length; j++) {
          const logs = this.unreadLogs[j];
          if(buddy.phonenumber === logs.runner) {
            buddy.unReadLogsCount = logs.count;
            break;
          }
        }
      }
    }
  }

  getProfilePic() {
    this.imageSrc = this.workoutService.getProfilePics();
  }

  buddyGroupChange(newBuddy) {
    this.buddygroup = newBuddy;
    this.getRunners();
  }

  selectBuddy(buddy) {
    this.router.navigate(['coachlog', buddy.phonenumber, this.currentDate], { queryParams: { name: buddy.name } });
  }

  getToday() {
    const today = new Date();
    const pipe = new DatePipe('en-US');
    const todayString =  pipe.transform(today, 'yyyy-MM-dd');
    return todayString;
  }

  getDate(date: string, noOfDays: number) {
    const d = new Date(date);
    d.setDate( d.getDate() + noOfDays );
    const pipe = new DatePipe('en-US');
    return pipe.transform(d, 'yyyy-MM-dd');
  }

  onDateSelection(date: NgbDate) {
    this.currentDate = date.year + '-' + date.month + '-' + date.day ;
    this.dateString = this.getDateString(date);
    this.getRunners();
  }

  onTabChange($event: NgbTabChangeEvent) {
    this.selectedTab = $event.nextId;
    this.getRunners();
  }

  // markAttendance() {
  //   this.getAttendanceCount();
  //   this.workoutService.markAttendance(this.currentDate, this.getSelectedBuddies()).subscribe();
  // }

  markAttendance(buddy) {
    console.log(buddy);
    const data = {
      scheduledworkoutdate: buddy.WorkoutDate,
      phonenumber: buddy.phonenumber,
      status: buddy.isPresentByCoach
    }
    this.getAttendanceCount();
    this.workoutService.updateAttendance(data).subscribe();
  }


  getSelectedBuddies() {
    const selectedList = [];
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.buddies.length; index++) {
      const buddy = this.buddies[index];
      if(buddy.isPresentByCoach) {
        selectedList.push(buddy.phonenumber);
      }
    }
    return selectedList;
  }

  getDateString(day: NgbDate): string {
    const today = new Date();
    const date = new Date(day.year, day.month-1, day.day);
    if(date === today) {
      return 'Today';
    } else {
      const pipe = new DatePipe('en-US');
      return pipe.transform(date, 'yyyy-MM-dd');
    }
  }

  locationChange(location) {
    this.currentLocation = location;
    this.getRunners();
  }

  updateUrl(data) {
    data.target.src = '../assets/images/runner-default.jpg';
  }
}
