import { Component, OnInit, NgModule, ViewChild, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { WorkoutService } from '../workout.service';
import { Schedule } from '../schedule';
import { AppService } from '../app.service';
import { RunnerService } from '../runner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  schedules: Schedule[] = [];
  weekScheduleRange = '';
  profile: any;
  phonenumber: string;
  isCoach: boolean;
  weekRange: any;
  STLinks: any;
  activationLinks: any;
  runningDrillsLinks: any;
  stretchesLinks: any;
  footDrillsLinks: any;
  weekNumber: number;
  unityWeekDistanceLogged: number;
  unityWeekBuddyGroupDistanceLogged: number;
  unityWeekRHGroupDistanceLogged: number;
  @ViewChild('popup') popup;
  @Input() name: string;

  constructor(
    private appService: AppService,
    private workoutService: WorkoutService,
    private runnerService: RunnerService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    route.params.subscribe(val => {
      this.phonenumber = this.route.snapshot.paramMap.get('phonenumber');
    });
  }

  ngOnInit() {
    this.appService.setTitle('Schedule');
    const runnerDetails = this.runnerService.getRunnerDetails();
    this.profile = runnerDetails.profile;
    this.weekNumber = runnerDetails.weekNumber;
    if(!this.phonenumber) {
      this.phonenumber = this.profile.phonenumber;
    }
    this.isCoach = this.phonenumber !== this.profile.phonenumber;


    this.getDefaultScheduleRange();
    if(this.isCoach) {
      this.getSchedule(this.weekRange.fromDate, this.weekRange.toDate);
    } else {
      this.getCurrentSchedule();
    }
    this.getWeekScheduleRange();
    this.workoutService.getUnityWeekStats().subscribe(data => {
      if (data.self) {
        const runnerRow = data.self.find((row) => {
          return row.idUnityWeekReferral === null
        });
        if (runnerRow){
          this.unityWeekDistanceLogged = runnerRow["sum(duration)"];
        } else {
          this.unityWeekDistanceLogged = 0.0;
        };
      }
      this.unityWeekBuddyGroupDistanceLogged = data.BGStats[0]["sum(duration)"];
      this.unityWeekRHGroupDistanceLogged = data.RHStats[0]["sum(duration)"];
    });
  }

  getCurrentSchedule() {
    this.schedules = this.workoutService.getCurrentSchedule();
    this.STLinks = this.workoutService.getCurrentStlinks();
    this.activationLinks = this.workoutService.getActivationLinks();
    this.stretchesLinks = this.workoutService.getStretchesLinks();
    this.footDrillsLinks = this.workoutService.getFootDrillsLinks();
    this.runningDrillsLinks = this.workoutService.getRunningDrillsLinks();
    if(this.schedules && this.schedules.length > 0) {
      // this.getDefaultScheduleRange();
    } else {
      this.getDefaultScheduleRange();
    }
  }

  getDefaultScheduleRange() {
    this.weekRange = this.nextDay();
  }

  getSchedule(fromDate: string, toDate: string) {
    this.workoutService.getSchedule(this.phonenumber, fromDate, toDate).subscribe(data => {
      if(data && data.Schedule && data.Schedule.length) {
        // this.workoutService.setCurrentSchedule(data.Schedule);
        this.schedules = data.Schedule;
        this.weekNumber = data.weekNumber;
        if(data.STLinks && data.STLinks.length) {
          this.STLinks = data.STLinks[0];
        }
        this.weekRange.fromDate = this.schedules[0].scheduledworkoutdate;
        this.weekRange.toDate = this.schedules[6].scheduledworkoutdate;

        // this.getCurrentSchedule();
        this.getWeekScheduleRange();
      }
    });
  }

  getPrevSchedule() {
    const fromDate = this.getDate(this.weekRange.fromDate, -7);
    const toDate = this.getDate(this.weekRange.toDate, -7);
    this.getSchedule(fromDate, toDate);
  }

  getNextSchedule() {
    const fromDate = this.getDate(this.weekRange.fromDate, 7);
    const toDate = this.getDate(this.weekRange.toDate, 7);
    this.getSchedule(fromDate, toDate);
  }

  getWeekScheduleRange() {
    let startDate;
    let endDate;
    if(this.schedules && this.schedules.length) {
      startDate = this.schedules[0].scheduledworkoutdate;
      endDate = this.schedules[6].scheduledworkoutdate;
    } else {
      startDate = this.weekRange.fromDate;
      endDate = this.weekRange.toDate;
    }
    this.weekScheduleRange = startDate.split('-')[2] + ' - ' + endDate.split('-').reverse().join(' . ');
  }

  isNotUnityWeek(){
    return this.runnerService.isNotUnityWeekSchedule(this.weekNumber);
  }

  isUnityWeek(){
    return this.runnerService.isUnityWeekSchedule(this.weekNumber);
  }

  getDate(date: string, noOfDays: number) {
    const d = new Date(date);
    d.setDate( d.getDate() + noOfDays );
    const pipe = new DatePipe('en-US');
    return pipe.transform(d, 'yyyy-MM-dd');
  }

  openLog(schedule: Schedule) {
    if(this.isFutureDate(schedule.scheduledworkoutdate)) {
      // popup
      this.openPoppup();
      return;
    }
    this.router.navigate(['log', this.phonenumber, schedule.scheduledworkoutdate], { queryParams: { name: this.name } });
  }

  isFutureDate(date) {
    let isFutureDate = false;
    const scheduledate = new Date(date);
    const today = new Date();
    if(scheduledate > today) {
      isFutureDate = true;
    }
    return isFutureDate;
  }

  openPoppup() {
    this.modalService.open(this.popup, {centered: true});
  }

  nextDay(){
    const pipe = new DatePipe('en-US');
    const date = new Date();
    const lastday = date.getDate() - (date.getDay() - 1);
    date.setDate(lastday);
    const range = {toDate: '', fromDate: ''};
    range.fromDate = pipe.transform(date, 'yyyy-MM-dd');
    date.setDate(date.getDate() + 6);
    range.toDate = pipe.transform(date, 'yyyy-MM-dd');
    return range;
  }

  openStlink(event) {
    event.stopPropagation();
    window.open(this.STLinks.url, '_blank');
  }

  openActivationlink(event) {
    event.stopPropagation();
    window.open(this.activationLinks.url, '_blank');
  }

  openRunningDrillslink(event) {
    event.stopPropagation();
    window.open(this.runningDrillsLinks.url, '_blank');
  }

  
  openFootdrillslink(event) {
    event.stopPropagation();
    window.open(this.footDrillsLinks.url, '_blank');
  }

  openStretcheslink(event) {
    event.stopPropagation();
    window.open(this.stretchesLinks.url, '_blank');
  }

}
