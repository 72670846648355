import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  private referralUrl = environment.baseUrl + 'runner';
  constructor(private http: HttpClient) { }

  private httpOptions = { headers: new HttpHeaders(), withCredentials: true };


  addReferral(name, email) {
    const referral = {
      "referrals": [
        {
          "referral_name": name,
          "referral_email": email
        }
      ]};
    
    // const user = {phonenumber: '2537230968', password: 'RH2537230968'};
    const url = this.referralUrl + '/addUnityWeekReferral';

    return this.http.post<any>(url, referral, this.httpOptions);
                // .pipe(catchError(this.handleError<any>('catch error')));
  }

  removeReferral(name, email) {
    const referral = {
      "referrals": [
        {
          "referral_name": name,
          "referral_email": email
        }
      ]};
    // const user = {phonenumber: '2537230968', password: 'RH2537230968'};
    const url = this.referralUrl + '/removeUnityWeekReferral';

    return this.http.post<any>(url, referral, this.httpOptions)
                .pipe(catchError(this.handleError<any>('catch error')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
