import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleComponent } from './schedule/schedule.component';
import { SigninComponent } from './signin/signin.component';
import { LogComponent } from './log/log.component';
import { NotLoggedInGuard } from './not-login-guard.service';
import { LoggedInGuard } from './login-guard.service';
import { AccountComponent } from './account/account.component';
import { CoachComponent } from './coach/coach.component';
import { CoachLogComponent } from './coach-log/coach-log.component';
import { CoachGuardService } from './coach-guard.service';
import { ProfileComponent } from './profile/profile.component';
import { CalenderComponent } from './calender/calender.component';

const routes: Routes = [
  { path: '', redirectTo: '/schedule', pathMatch: 'full' },
  { path: 'profile', component: ProfileComponent, canActivate: [LoggedInGuard] },
  { path: 'runner/:phonenumber', component: ProfileComponent, canActivate: [LoggedInGuard, CoachGuardService] },
  { path: 'schedule', component: ScheduleComponent, canActivate: [LoggedInGuard] },
  { path: 'log/:phonenumber/:logDate', component: LogComponent, canActivate: [LoggedInGuard, CoachGuardService] },
  { path: 'calendar', component: CalenderComponent, canActivate: [LoggedInGuard] },
  { path: 'account', component: AccountComponent, canActivate: [LoggedInGuard] },
  { path: 'coach', component: CoachComponent, canActivate: [LoggedInGuard] },
  { path: 'coachlog/:phonenumber/:logDate', component: CoachLogComponent, canActivate: [LoggedInGuard, CoachGuardService] },
  { path: 'signin', component: SigninComponent, canActivate: [NotLoggedInGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
