import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class RunnerService {

  private runnerUrl = environment.baseUrl + 'runner';
  private stravaUrl = environment.stravaUrl;

  constructor(private http: HttpClient, private router: Router) { }

  private getCookie(cname: string) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  public getToken() {
    // return this.getCookie('Runners-high');
    return localStorage.getItem('token');
  }

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  private setRunnerDetails(runnerDetails: any) {
    localStorage.setItem('runner-details', JSON.stringify(runnerDetails));
  }

  public setUnityWeekReferrals(referrals){
    const runnerDetails = localStorage.getItem('runner-details');
    if (runnerDetails){
      const runnerDetailsObj = JSON.parse(runnerDetails);
      runnerDetailsObj.profile.referrals = referrals;
      this.setRunnerDetails(runnerDetailsObj);
    }
  }

  public getRunnerDetails() {
    const runnerDetails = localStorage.getItem('runner-details');
    if (runnerDetails) {
      return JSON.parse(runnerDetails);
    }
    return ;
  }

  public getUnityWeekStart(){
    return environment.unityWeekStart;
  }

  public getUnityWeekEnd(){
    return environment.unityWeekEnd;
  }

  public isUnityWeek(dateString){
    const logDate = new Date(dateString);
    const unityStartDate = new Date(this.getUnityWeekStart());
    const unityEndDate = new Date(this.getUnityWeekEnd());
    if (logDate>=unityStartDate && logDate <= unityEndDate){
      return true;
    }
    return false;
  }

  public isUnityWeekSchedule(number){
    return environment.unityWeekNumber === number;
  }

  public isNotUnityWeekSchedule(number){
    return environment.unityWeekNumber !== number;
  }

  public getIsCoach() {
    let isCoach = false;
    const runner = this.getRunnerDetails();
    if (runner && runner.profile.roles) {
      for (const iterator of runner.profile.roles) {
        if(iterator === 'coach') {
          isCoach = true;
        }
      }
    }
    return isCoach;
  }

  /** GET heroes from the server */
  login(phonenumber: string, password: string): Observable<any> {
    const user = {phonenumber, password};
    // const user = {phonenumber: '2537230968', password: 'RH2537230968'};

    const encodedData = btoa(JSON.stringify(user));
    const authContent = 'Bearer ' + encodedData;

    const httpOptionsLogin = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', authContent),
      observe: 'response' as 'response'
    };

    return this.http.post<any>(this.runnerUrl + '/login', {}, httpOptionsLogin).pipe(
      tap(resp => {
        if (resp.status === 200) {
          this.setToken(authContent);
          this.setRunnerDetails(resp.body);
        }
      }),
      catchError(this.handleError<any>('catch error'))
    );
  }

  logout() {
    localStorage.removeItem('runner-details');
    localStorage.removeItem('token');
    this.router.navigate(['signin']);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private handleSuccessResponse = (response) => {
    // console.log('Request: success', response);
    if (response.headers.get('access-token')) {

    }
    return response.body;
  }
}
