import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {

  transform(item: any): any {
    if(!item) { return ''; }
    return this.pad(+item.split(':')[0]) + ':' + this.pad(+item.split(':')[1]) + ':' + this.pad(+item.split(':')[2]);
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

}
