import { Component, OnInit, Input } from '@angular/core';
import { WorkoutService } from '../workout.service';
import { RunnerService } from '../runner.service';
import { Feedback } from '../feedback';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  @Input() feedbackDate: string;
  @Input() feedbacks: Feedback[];
  @Input() isCoachView: boolean;

  profile: any;
  feedbackMessage: string;
  phonenumber: string;
  CHATEXPIREDAYS = 14;
  isChatExpired: boolean;

  constructor(
    private route: ActivatedRoute,
    private workoutService: WorkoutService,
    private runnerService: RunnerService
  ) {
    route.params.subscribe(val => {
      this.phonenumber = this.route.snapshot.paramMap.get('phonenumber');
      this.profile = this.runnerService.getRunnerDetails().profile;
      this.isChatExpired = this.checkChatExpired();
    });
  }

  ngOnInit() {
    this.isChatExpired = this.checkChatExpired();
  }

  sendFeedback() {
    if(this.feedbackMessage) {
      this.setFeedback(this.feedbackMessage);
    }
  }

  deleteMessage(messageId: number) {
    this.workoutService.deleteFeedback(messageId).subscribe(data => {
      this.removeMessage(messageId);
    });
  }

  removeMessage(messageId: number) {
    // tslint:disable-next-line: prefer-for-of
    for(let i = 0; i < this.feedbacks.length; i++) {
      if(messageId === this.feedbacks[i].idMessage) {
        this.feedbacks.splice(i, 1);
      }
    }
  }

  setFeedback(message: string) {
    const phoneNumber = this.profile.phonenumber;
    this.workoutService.setFeedback(this.phonenumber, this.feedbackDate, message).subscribe(data => {
      if(data) {
        this.feedbackMessage = '';
        const feedbackData = {
          createdat: new Date().toDateString(),
          from_name: this.profile.name,
          from_phonenumber: phoneNumber,
          message,
          to_name: '',
          to_phonenumber: '',
          idMessage: data.identifiers[0].idMessage
        }
        this.feedbacks.push(feedbackData);
      }
    });
  }

  checkChatExpired() {
    if(!this.feedbackDate) {
      return false;
    }
    const today = new Date();
    const fDate = new Date(this.feedbackDate);
    // get total seconds between two dates
    const diff = Math.abs(today.getTime() - fDate.getTime());
    const days = Math.ceil(diff / (1000 * 3600 * 24));
    console.log(days, this.feedbackDate);
    return days > this.CHATEXPIREDAYS;
  }

}
