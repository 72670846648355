import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkoutService } from '../workout.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-coach-log',
  templateUrl: './coach-log.component.html',
  styleUrls: ['./coach-log.component.css']
})
export class CoachLogComponent implements OnInit {

  phonenumber: string;
  logDate: string;
  unAcknowledgedLogs: any[];
  name: string;

  constructor(
    private route: ActivatedRoute,
    private workoutService: WorkoutService,
    private router: Router,
    private appService: AppService) {
    route.params.subscribe(val => {
      this.phonenumber = this.route.snapshot.paramMap.get('phonenumber');
      this.logDate = this.route.snapshot.paramMap.get('logDate');
      this.name = this.route.snapshot.queryParamMap.get('name');

      this.getUnAcknowledgedLogs(this.phonenumber, this.logDate);
    });
  }

  ngOnInit() {
    this.appService.setTitle('Runner Log');
  }

  getUnAcknowledgedLogs(phonenumber: string, logDate: string) {
    this.workoutService.getUnAcknowledgedLogs(phonenumber, logDate, logDate).subscribe(data => {
      if(data) {
        this.unAcknowledgedLogs = data.WorkoutLog;
      }
    });
  }

  goToLog(log: any) {
    this.router.navigate(['log', log.phonenumber, log.scheduledworkoutdate],
    { queryParams: { name: this.name } });
  }

}
