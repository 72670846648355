import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RunnerService } from './runner.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
    constructor(
        private router: Router,
        private runnerService: RunnerService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.runnerService.getToken()) {
            return true;
        } else {
            this.router.navigate(['/signin']);
            return false;
        }
    }
}
