import { Component, OnInit } from '@angular/core';
import {NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { RunnerService } from '../runner.service';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { WorkoutService } from '../workout.service';
import { Feedback } from '../feedback';


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
})
export class CalenderComponent implements OnInit {
  model: NgbDateStruct;
  date: NgbDate;
  maxDate: any;
  profile: any;
  phonenumber: string;
  unReadMessages: any[];

  constructor(
    private calendar: NgbCalendar,
    private runnerService: RunnerService,
    private router: Router,
    private appService: AppService,
    private workoutService: WorkoutService,
    private parserFormatter: NgbDateParserFormatter) { }

  selectToday() {
    this.model = this.calendar.getToday();
  }
  ngOnInit() {
    this.appService.setTitle('Calendar');
    this.profile = this.runnerService.getRunnerDetails().profile;
    this.phonenumber = this.profile.phonenumber;

    const today = new Date();
    this.maxDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};

    this.getUnReadMessages();
  }

  onDateSelection(date: NgbDate) {
    const scheduleDate = date.year + '-' + this.pad(date.month) + '-' + this.pad(date.day);
    this.router.navigate(['log', this.phonenumber, scheduleDate], { queryParams: { messages: true }});
  }

  // isDisabled = (date: NgbDate, current: {month: number}) => date.month !== current.month;
  isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  getUnReadMessages() {
    this.workoutService.getUnReadMessages(this.phonenumber).subscribe(messages => {
      this.unReadMessages = messages.filter(item => {
        return item.from_phonenumber === this.profile.coachPhonenumber;
      });
    });
  }

  hasMessage = (date: NgbDate) => {
    // return this.calendar.getWeekday(date) >= 6;
    const parserFormatter = this.parserFormatter;
    if(this.unReadMessages && this.unReadMessages.length) {
      const list = this.unReadMessages.filter(message => {
        return message.WorkoutDate === parserFormatter.format(date);
      });
      if(list && list.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

}
